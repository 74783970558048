
import { computed, defineAsyncComponent, defineComponent } from '@vue/runtime-core';
import { i18n, LANGUAGE } from '@/features/i18n/i18n-translate';

export default defineComponent({
    components: {
        AppBackHeader: defineAsyncComponent(() => import('@/components/app-back-header.vue')),
    },
    setup: () => ({
        isNorsk: computed(() => i18n.locale === LANGUAGE.NO),
        isEnglish: computed(() => i18n.locale === LANGUAGE.EN),
    }),
});

<template>
    <app-back-header />
    <article class="page-soknad" v-if="isNorsk">
        <h1 class="page-soknad__title"><span class="page-soknad__lead">Vi søker</span> Software Support Ingeniør</h1>

        <p>
            Brenner du for systematisk feilsøking og problemløsing av softwareløsninger? Er du serviceinnstilt, ikke
            redd for å ta ansvar og evner å arbeide selvstendig? Da er denne stillingen for deg.
        </p>

        <p>
            Vi er en nystartet virksomhet som bidrar til utvikling og support av softwaren Drilling Process Platform
            (DPP) for Maersk Drilling A/S i tett samarbeid med noen av de fremste, digitale oljeselskapene. DPP er
            banebrytende software som digitaliserer operasjonsprosedyrer på borerigger og dermed sikrer en konsistent,
            effektiv og transparent boreoperasjon.
        </p>

        <p>
            Du vil bli en del av et raskt, høytytende team bestående av utviklere, UX’ere, fageksperter, frontpersonell
            og vil være en del i å revolusjonere boreoperasjonen på avancerede borerigger. Du vil bli med i teamet i
            starten av en vekstreise for en ny digital løsning.
        </p>

        <p>
            I stillingen som Software Support Ingeniør vil din hovedoppgave være å supportere softwareløsningen DPP.
            Dette innebærer support mot rigger i operasjon. Her kreves presis og rask support hele døgnet. Du vil derfor
            inngå i en 2. linje support-døgnvaktordning. I kjernetiden (dagtid ukedager) vil du arbeide med forskjellige
            software/prosjektleveranser. Stillingen er 100% og arbeidstid tilpasses etter vaktordningen.
        </p>

        <p>Vi søker etter personer som har kompetanse på flere av teknologiene som inngår i softwareløsningen:</p>

        <ul>
            <li>Vue/JS</li>
            <li>C# .Net Core</li>
            <li>SQL server</li>
            <li>Postgresql</li>
            <li>Windows server</li>
            <li>IIS</li>
            <li>RabbitMq</li>
            <li>OPC UA</li>
            <li>Azure cloud tjenester</li>
            <li>Nettverk</li>
        </ul>

        <p>
            Vi ser for oss en kandidat som enten er nyutdannet software/IT ingeniør eller har erfaring innenfor software
            support. Dette kan bli din første stilling etter studiet, hvor du gradvis blir kjent med utviklerfaget. Her
            kreves det altså ikke årevis med erfaring, men en lyst til å sette seg inn i nye ting, gode
            kommunikasjonsevner og evne til å ta ansvar og arbeide selvstendig.
        </p>

        <p>
            Videre er det selvfølgelig et krav at du kan arbeide i en døgnvaktordning som innebærer jobb i helgene.
            Videre vil du bli tilbudt å reise offshore for å delta i distribusjon og implementering av løsninger over
            tid.
        </p>

        <h2>Vi tilbyr:</h2>
        <ul>
            <li>Konkurransedyktig lønn</li>
            <li>God forsikring og pensjonsordning</li>
            <li>Faglige utfordringer</li>
            <li>Gode utviklingsmuligheter</li>
            <li>
                Du kan lese mer om virksomheten på mako.as og om prosjektet her:
                <a
                    href="https://investor.maerskdrilling.com/static-files/567bf7b2-5b61-4b92-98c5-5fcb0c5bfc11"
                    target="_blank"
                    >Maersk Drilling Annual Report 2020</a
                >, side 22.
            </li>
        </ul>

        <p>
            Vi ser frem til å motta din søknad og CV, som sendes til
            <a href="mailto:contact@mako.as">contact@mako.as</a>
        </p>

        <p>Søknadsfrist: snarest</p>

        <p>
            Har du ytterligere spørsmål til stillingen kan du kontakte Mikael Skov:
            <a href="tel:004791114005">+47 911 14 005</a> eller Christoffer Jacobsen:
            <a href="tel:004747908083">+47 479 08 083</a>
        </p>
    </article>
    <article class="page-soknad" v-else-if="isEnglish">
        <h1 class="page-soknad__title">
            <span class="page-soknad__lead">We are looking for</span> Software Support Ingeniør
        </h1>

        <p>
            Are you passionate about troubleshooting and problem solving of software solutions? Are you service-minded,
            not afraid to take responsibility and have the ability to work independently? Then this position is for you.
        </p>

        <p>
            We are a newly started company that contributes to the development and support of the software Drilling
            Process Platform (DPP) for Maersk Drilling A/S in close collaboration with leading, digitally mature oil
            companies. DPP is groundbreaking software that digitizes operating procedures on drilling rigs and thus
            ensures a consistent, efficient and transparent drilling operation.
        </p>

        <p>
            You will become part of a fast-paced high-performing team composed of developers, UX’ers, subject matter
            experts, front-personnel and will be a part in revolutionizing how well construction operations are executed
            on advanced drilling rigs. You will join the team in the start of a growth journey of a new digital
            solution.
        </p>

        <p>
            In the position as a Software Support Engineer, your main task will be to support the software solution DPP.
            This means support for rigs in operation. Precise and fast support is required here around the clock. You
            will therefore be part of a 2nd line round-the-clock support team. In the core time (business hours) you
            will work with different software / project deliveries. The position is 100% and working hours are adjusted
            according to the shift schedule.
        </p>

        <p>
            We are looking for people who have expertise in several of these technologies included in the software
            solution:
        </p>

        <ul>
            <li>Vue/JS</li>
            <li>C# .Net Core</li>
            <li>SQL server</li>
            <li>Postgresql</li>
            <li>Windows server</li>
            <li>IIS</li>
            <li>RabbitMq</li>
            <li>OPC UA</li>
            <li>Azure cloud tjenester</li>
            <li>Nettverk</li>
        </ul>

        <p>
            We envision a candidate who is either a newly graduated software / IT engineer or has experience within
            software support. This can be your first position after your studies, where you gradually become familiar
            with the subject of software development. This does not require years of experience, but a desire to become
            acquainted with new things, good communication skills and the ability to take responsibility and work
            independently.
        </p>

        <p>
            Furthermore, it is of course a requirement that you can work in a round-the-clock on-demand schedule that
            also involves work on the weekends. Further, you will be offered to travel offshore to participate in
            deploying and implementing solutions over time.
        </p>

        <h2>We offer:</h2>
        <ul>
            <li>Competitive wages</li>
            <li>Good insurance and pension scheme</li>
            <li>Academic challenges</li>
            <li>Good development opportunities</li>
        </ul>

        <p>
            You can read more about the business at mako.as and about the project here:
            <a
                href="https://investor.maerskdrilling.com/static-files/567bf7b2-5b61-4b92-98c5-5fcb0c5bfc11"
                target="_blank"
                >Maersk Drilling Annual Report 2020</a
            >, page 22.
        </p>

        <p>
            We look forward to receive your application and CV, which is sent to
            <a href="mailto:contact@mako.as">contact@mako.as</a>
        </p>

        <p>Application deadline: soon</p>

        <p>
            If you have further questions about the position, you can contact Mikael Skov:
            <a href="tel:004791114005">+47 911 14 005</a> or Christoffer Jacobsen:
            <a href="tel:004747908083">+47 479 08 083</a>
        </p>
    </article>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from '@vue/runtime-core';
import { i18n, LANGUAGE } from '@/features/i18n/i18n-translate';

export default defineComponent({
    components: {
        AppBackHeader: defineAsyncComponent(() => import('@/components/app-back-header.vue')),
    },
    setup: () => ({
        isNorsk: computed(() => i18n.locale === LANGUAGE.NO),
        isEnglish: computed(() => i18n.locale === LANGUAGE.EN),
    }),
});
</script>

<style lang="scss">
.page-soknad {
    display: flex;
    flex-flow: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include container-small;
    &__title {
        font-size: 1.5rem;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        margin-bottom: 1.5rem;
    }
    &__lead {
        background: var(--secondary);
        color: #fff;
        display: block;
        font-size: 0.75rem;
        padding: 0.625rem;
        text-transform: uppercase;
        margin-bottom: 0.625rem;
    }
    p,
    h2,
    ul {
        line-height: 1.5;
        font-size: 1.1rem;
        &:not(:last-child) {
            margin-bottom: 0.625rem;
        }
    }
    h2 {
        font-weight: bold;
    }
    ul {
        list-style: circle;
        list-style-position: inside;
    }
}
</style>
